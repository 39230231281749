/* mapbox */
.mapboxgl-ctrl-logo {
    display: none !important;
}
.mapboxgl-ctrl-group {
    box-shadow: none !important;
    @apply border-input border;
}
.mapboxgl-ctrl-geolocate {
    @apply border-input bg-background dark:bg-foreground ring-offset-background hover:bg-accent hover:text-accent-foreground focus-visible:ring-ring cursor-pointer rounded border shadow-none transition-all duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 !important;
}

.number-input-no-arrows::-webkit-outer-spin-button,
.number-input-no-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-input-no-arrows {
    appearance: textfield;
    -moz-appearance: textfield;
}
